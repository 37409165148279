import TraineeInfoForm from './TraineeInfoForm';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import { useState, useEffect } from 'react';
import { getTrainingExpiration } from '../../helpers/trainingExpired';

// Set the output format for every react-moment instance.
Moment.globalFormat = 'MM/DD/YYYY';

function ReviewTraineeInfo() {
  const language = useSelector((state) => state.language.active_language);
  const active_trainee = useSelector((state) => state.trainees.active_trainee);
  const returning_trainee = typeof active_trainee?.id === 'string';
  const [trainingExpired, setTrainingExpired] = useState(false);
  const [trainingExpirationDate, setTrainingExpirationDate] = useState(null);
  const location_expiration_type =
    useSelector((state) => state.location.expiration_time) || '365'; // can be 'year', 'calendar-year', or 'custom-date'
  const customExpirationDate =
    useSelector((state) => state.location.custom_expiration_date) || '01/01';

  useEffect(() => {
    if (active_trainee) {
      const { expired, expirationDate } = getTrainingExpiration(
        active_trainee,
        location_expiration_type,
        customExpirationDate
      );

      setTrainingExpired(expired);
      setTrainingExpirationDate(expirationDate);
    }
  }, [active_trainee, location_expiration_type, customExpirationDate]);

  const traineeHeader = returning_trainee
    ? language.form_welcome_back_text
    : language.form_new_here_text;

  const traineeSubText = returning_trainee
    ? language.form_welcome_back_subtext
    : language.form_new_here_subtext;

  return (
    <>
      <div className="whos-training-wrapper">
        <div>
          <h2 className="training-heading">{traineeHeader}</h2>
          <p className="training-text">{traineeSubText}</p>
        </div>

        {returning_trainee && (
          <div id="site-training-expiration-wrapper">
            {trainingExpired ? (
              <>
                <p className="bold red-text">
                  {language.form_training_expired_text}
                </p>
                <p className="bold red-text">
                  <Moment date={trainingExpirationDate} format="MM/DD/YYYY" />
                </p>
              </>
            ) : (
              <>
                <p>{language.form_training_will_expire_text}</p>
                <p className="bold">
                  <Moment date={trainingExpirationDate} format="MM/DD/YYYY" />
                </p>
              </>
            )}
          </div>
        )}
      </div>
      <TraineeInfoForm />
    </>
  );
}

export default ReviewTraineeInfo;
